import React, {useEffect} from "react";
import enlilImage from "../../assets/logo-5cd08655.svg";
import css_self from "./css/NavBar.module.scss";
import "./css/NavBar.scss";
import {Tooltip, Avatar, Box, Button, IconButton, InputAdornment, Link, Menu, MenuItem, Popover, TextField, Typography} from "@mui/material";
import {Blue3, Grey2, Grey17, Grey6, White} from "../../theme/styles";
import {useNavigate} from "react-router-dom";
import {Apps, Folder, SyncAlt, SupervisorAccount, WorkOutline, ThumbUp, Checklist, HelpOutlineOutlined, Language, Logout, Menu as MenuIcon, PermIdentity, Search} from "@mui/icons-material";
import useAppContext from "../../util/AppContext";
import useBreakpoint from "../../util/useBreakpoint";
import Login from "../Login/Login";
import EventBus from "../../util/EventBus";
import {Company} from "../../util/SessionContext";
import {Screen} from "../../util/SessionContext";

type MenuSelection = {id: string, target: EventTarget & HTMLButtonElement}


export default function NavBar() {
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = React.useState<boolean>(false);
    const [renderDocumentScreen, setRenderDocumentScreen] = React.useState<boolean>(false);
    const [renderApprovalRequestScreen, setRenderApprovalRequestScreen] = React.useState<boolean>(false);
    const [renderRecordScreen, setRenderRecordScreen] = React.useState<boolean>(false);
    const [renderEquipmentScreen, setRenderEquipmentScreen] = React.useState<boolean>(false);
    const [renderEquipmentSpecificationScreen, setRenderEquipmentSpecificationScreen] = React.useState<boolean>(false);
    const [renderSupplierScreen, setRenderSupplierScreen] = React.useState<boolean>(false);
    const [renderPurchaseOrderScreen, setRenderPurchaseOrderScreen] = React.useState<boolean>(false);
    const [renderReceivingScreen, setRenderReceivingScreen] = React.useState<boolean>(false);
    const [renderPartScreen, setRenderPartScreen] = React.useState<boolean>(false);
    const [renderEngineeringBuildScreen, setRenderEngineeringBuildScreen] = React.useState<boolean>(false);
    const [renderLHRScreen, setRenderLHRScreen] = React.useState<boolean>(false);
    const [renderLotScreen, setRenderLotScreen] = React.useState<boolean>(false);
    const [renderWorkOrderScreen, setRenderWorkOrderScreen] = React.useState<boolean>(false);
    const [renderTrainingScreen, setRenderTrainingScreen] = React.useState<boolean>(false);
    const [renderTrainingCurriculumScreen, setRenderTrainingCurriculumScreen] = React.useState<boolean>(false);
    const [renderAdministrationScreen, setRenderAdministrationScreen] = React.useState<boolean>(false);
    const [menu, setMenu] = React.useState<MenuSelection | null>(null);
    const appContext = useAppContext();

    const sessionUpdateListener = () => {
        // @ts-ignore
        let _showLoginDialog: boolean = appContext?.sessionContext.getShowLoginDialog();

        setShowLogin(_showLoginDialog);

        if (appContext?.sessionContext.getScreens() && appContext?.sessionContext.getScreens().length > 0) {
            const documentScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Document");
            if (documentScreen && documentScreen.Views.length > 0) setRenderDocumentScreen(true)

            const renderApprovalRequestScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Approval Request");
            if (renderApprovalRequestScreen && renderApprovalRequestScreen.Views.length > 0) setRenderApprovalRequestScreen(true)

            const renderRecordScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Record");
            if (renderRecordScreen && renderRecordScreen.Views.length > 0) setRenderRecordScreen(true)

            const renderEquipmentScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Equipment");
            if (renderEquipmentScreen && renderEquipmentScreen.Views.length > 0) setRenderEquipmentScreen(true)

            const renderEquipmentSpecificationScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Equipment Specification");
            if (renderEquipmentSpecificationScreen && renderEquipmentSpecificationScreen.Views.length > 0) setRenderEquipmentSpecificationScreen(true)

            const renderSupplierScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Supplier");
            if (renderSupplierScreen && renderSupplierScreen.Views.length > 0) setRenderSupplierScreen(true)

            const renderPurchaseOrderScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Purchase Order");
            if (renderPurchaseOrderScreen && renderPurchaseOrderScreen.Views.length > 0) setRenderPurchaseOrderScreen(true)

            const renderReceivingScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Receiving");
            if (renderReceivingScreen && renderReceivingScreen.Views.length > 0) setRenderReceivingScreen(true)

            const renderPartScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Part");
            if (renderPartScreen && renderPartScreen.Views.length > 0) setRenderPartScreen(true)

            const renderEngineeringBuildScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Engineering Build");
            if (renderEngineeringBuildScreen && renderEngineeringBuildScreen.Views.length > 0) setRenderEngineeringBuildScreen(true)

            const renderLHRScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "LHR");
            if (renderLHRScreen && renderLHRScreen.Views.length > 0) setRenderLHRScreen(true)

            const renderLotScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Lot");
            if (renderLotScreen && renderLotScreen.Views.length > 0) setRenderLotScreen(true)

            const renderWorkOrderScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Work Order");
            if (renderWorkOrderScreen && renderWorkOrderScreen.Views.length > 0) setRenderWorkOrderScreen(true)

            const renderTrainingScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Training");
            if (renderTrainingScreen && renderTrainingScreen.Views.length > 0) setRenderTrainingScreen(true)

            const renderTrainingCurriculumScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Training Curriculum");
            if (renderTrainingCurriculumScreen && renderTrainingCurriculumScreen.Views.length > 0) setRenderTrainingCurriculumScreen(true)

            const renderAdministrationScreen: Screen | undefined = appContext?.sessionContext.screens.find((screen: Screen) => screen.Name === "Administration");
            if (renderAdministrationScreen && renderAdministrationScreen.Views.length > 0) setRenderAdministrationScreen(true)
        }
    };

    useEffect(() => {
       // @ts-ignore
        EventBus.session.addListener("update", sessionUpdateListener);

        sessionUpdateListener();

       // @ts-ignore
        return () => {
            EventBus.session.removeListener("update", sessionUpdateListener);
        }
    }, []);

    let adornments = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton onClick={() => {}}>
                    <Search/>
                </IconButton>
            </InputAdornment>
        )
    };

    let content;
    let breakpoint = useBreakpoint();

    if (breakpoint.getBreakPointName() === "xl" || breakpoint.getBreakPointName() === "lg") {
        content = (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 20, paddingRight: 5, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: Grey2, backgroundColor: White}}>
                    <div key={1} style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px"}}>
                        <div key={1} style={{paddingTop: "0px", paddingRight: "2px", marginLeft: "-5px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                        <div key={2} style={{paddingTop: "5px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                        <div key={3} style={{paddingTop: "7px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>ETL</Typography></div>
                        <div style={{paddingTop: "5px", marginLeft: "80px"}}>
                            <Button className={css_self.NavMenuButton} variant="text" onClick={() => navigate("/home")}>Home</Button>
                        </div>
                    </div>
                    <div key={2} style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", flexGrow: 1, height: "40px", paddingTop: "6px", marginLeft: "30px"}}>
                        <TextField fullWidth style={{width: "100%"}} id="outlined-read-only-input" size="small" placeholder="Ask Lily..." InputProps={adornments}/>
                    </div>
                    <div key={3} style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", height: "40px", minWidth: "260px", paddingTop: "4px"}}>
                        <div style={{width: "40px", paddingLeft: "5px", paddingTop: "1px", height: "40px"}}>
                            <IconButton className={css_self.NavMenuButton} onClick={(e) => setMenu({id: (menu?.id === "sites" ? "" : "sites"), target: e.currentTarget})}>
                                <Language/>
                            </IconButton>
                        </div>
                        {appContext?.sessionContext.valid() ? (
                            <div style={{width: "40px", paddingLeft: "5px", paddingTop: "1px", height: "40px"}}>{renderVisibilityNavigation()}</div>
                        ) : null}
                        <div style={{width: "40px", paddingLeft: "5px", paddingTop: "1px", height: "40px"}}>{renderAccountNavigation()}</div>
                        {appContext?.sessionContext.valid() ? (
                            <div style={{width: "40px", paddingLeft: "5px", paddingTop: "1px", height: "40px"}}>{renderAppsNavigation()}</div>
                        ) : null}
                    </div>
                </div>
                {renderSiteNavigation()}
            </div>
        )
    } else {
        content = (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 10, paddingRight: 5, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: Grey2, backgroundColor: White, height: "51px"}}>
                    <div key={1} style={{marginTop: "3px"}}>{renderMenuNavigation()}</div>
                    <div key={2} style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <div>
                            <img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/>
                        </div>
                        <div style={{paddingLeft: "70px", paddingTop: "4px"}}>
                            <Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography>
                        </div>
                        <div>
                            <Typography style={{paddingLeft: "10px", paddingTop: "6px", fontSize: 24, fontWeight: 400, color: Blue3}}>ETL</Typography>
                        </div>
                    </div>
                    <div key={3} style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                        <div style={{width: "40px", paddingLeft: "0px", marginTop: "3px", height: "40px"}}>
                            <IconButton onClick={() => {}}>
                                <Search/>
                            </IconButton>
                        </div>
                        <div style={{width: "40px", paddingLeft: "5px", marginTop: "5px", height: "40px"}}>{renderAppsNavigation()}</div>
                    </div>
                </div>
                {menu?.id === "menu" ? (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "234px"}}>
                        <div style={{width: "100%"}}>
                            <div key={"help"} className={css_self.Mobile} onClick={() => {
                                navigate("/help");
                            }}>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <PermIdentity style={{marginTop: "-2px", color: Grey6}}/>
                                    <Typography style={{marginLeft: "10px", marginTop: "1px", fontSize: "15px", fontWeight: "400", color: Grey17}}>{appContext?.sessionContext.valid() ? "Log out" : "Log in"}</Typography>
                                </div>
                            </div>
                            <div key={"getEnlil"} className={css_self.Mobile}>
                                <Link href="https://www.enlil.com" target="_blank" style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Get Enlil</Link>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        )
    }

    return (
        <>{content}</>
    );

    function renderSiteNavigation() {
        let content = null;

        if (menu?.id === "sites") {
            content = (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    style={{width: "100%"}}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>BPM</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                </div>
            )
        }

        return content;
    }

    function stringAvatar(name: string) {
        return name
            .split(" ")
            .map(word => word[0])
            .join("")
            .substring(0, 2);
    }

    function renderAccountNavigation() {

        let accountMarkup = (
            <div>
                <IconButton className={css_self.NavMenuButton} onClick={() => {
                    if (appContext) { // @ts-ignore
                        appContext.sessionContext.showLoginDialog = true;
                    }
                    setShowLogin(true);
                }}>
                    <PermIdentity/>
                </IconButton>
            </div>
        )
        if (appContext?.sessionContext.valid()) {
            accountMarkup = (
                <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => setMenu({id: "account", target: e.currentTarget})}>
                    <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 35, height: 35}}>{stringAvatar(appContext?.sessionContext.getName())}</Avatar>
                </Button>
            )
        }

        return (
            <div>
                {showLogin ? <Login/> : null}
                {accountMarkup}
                {menu?.id === "account" ? (
                    <Menu anchorEl={menu.target}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                          transformOrigin={{vertical: -16, horizontal: "center"}}
                          open={true}
                          onClose={() => setMenu(null)}
                          TransitionProps={{
                              onExited: () => setMenu(null),
                          }}
                          MenuListProps={{
                              ...{"data-cc-component": "NavMenu"},
                              disablePadding: true,
                              style: {"minWidth": "200px"},
                          }}
                    >
                        <MenuItem key={"account"} disabled className={css_self.Standard}>
                            <Typography component="div" style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Company: <b>{appContext?.sessionContext.getCompanyName()}</b></Typography>
                        </MenuItem>
                        <MenuItem key={"name"} disabled className={css_self.Standard}>
                            <Typography component="div" style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Hello, <b>{appContext?.sessionContext.getName()}</b></Typography>
                        </MenuItem>
                        <MenuItem divider key={"help"} className={css_self.Standard} onClick={() => {
                            setMenu(null);
                            navigate("/help");
                        }}>
                            <HelpOutlineOutlined style={{color: Grey6}} />
                            <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Help</Typography>
                        </MenuItem>
                        <MenuItem divider key={"switchCompany"} className={css_self.Standard} onClick={() => {
                            setMenu(null);
                        }}>
                            <SyncAlt style={{color: Grey6}} />
                            <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Switch Company</Typography>
                        </MenuItem>
                        <MenuItem divider key={"logout"} className={css_self.Standard} onClick={() => {
                            setMenu(null);
                            navigate("/logout");
                        }}>
                            <Logout style={{color: Grey6}} />
                            <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Log out</Typography>
                        </MenuItem>
                    </Menu>
                ) : null}
            </div>
        );
    }

    function renderVisibilityNavigation() {
        let company: Company = appContext?.sessionContext.getCompany()!;

        return (
            <>
                <Tooltip title="Personas" placement="bottom">
                    <IconButton className={css_self.NavMenuButton} style={{marginTop: "-1px"}} onClick={(e) => setMenu({id: "visibility", target: e.currentTarget})}>
                        <SyncAlt />
                    </IconButton>
                </Tooltip>
                {menu?.id === "visibility" ? (
                    <Menu anchorEl={menu?.target}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                          transformOrigin={{vertical: -17, horizontal: "right"}}
                          open={true}
                          onClose={() => setMenu(null)}
                          TransitionProps={{
                              onExited: () => setMenu(null),
                          }}
                          MenuListProps={{
                              ...{"data-cc-component": "NavMenu"},
                              disablePadding: true,
                              style: {"minWidth": "300px"},
                          }}
                    >
                        {company.Positions.length > 0 ? (
                            <>
                                <MenuItem key="position-header" disabled className={css_self.Standard}>
                                    <div style={{display: "block", marginLeft: "10px"}}>
                                        <Typography style={{fontSize: "15px", color: Grey17, fontWeight: "400"}}>Positions</Typography>
                                    </div>
                                </MenuItem>
                                {company.Positions.map(position => (
                                    <MenuItem key={position.USID} className={css_self.Standard} onClick={() => {
                                        setMenu(null);
                                        appContext?.sessionContext.setActiveVisibility("Position", position.USID);
                                        EventBus.visibility.emit("change-visibility", "Position");
                                    }}>&emsp;&emsp;
                                        <SupervisorAccount style={{color: Grey17}} />
                                        <div style={{display: "block", marginLeft: "10px"}}>
                                            <Typography style={{fontSize: "15px", color: Grey17, fontWeight: "400"}}>{position.Name}</Typography>
                                        </div>
                                    </MenuItem>
                                ))}
                            </>
                        ) : null}
                        {company.UserGroups.length > 0 ? (
                            <>
                                <MenuItem key="userGroup-header" disabled className={css_self.Standard}>
                                    <div style={{display: "block", marginLeft: "10px"}}>
                                        <Typography style={{fontSize: "15px", color: Grey17, fontWeight: "400"}}>User Groups</Typography>
                                    </div>
                                </MenuItem>
                                {company.UserGroups.map(userGroup => (
                                    <MenuItem key={userGroup["USID"]} className={css_self.Standard} onClick={() => {
                                        setMenu(null);
                                        appContext?.sessionContext.setActiveVisibility("UserGroup", userGroup.USID);
                                        EventBus.visibility.emit("change-visibility", "UserGroup");
                                    }}>&emsp;&emsp;
                                        <ThumbUp style={{color: Grey17}} />
                                        <div style={{display: "block", marginLeft: "10px"}}>
                                            <Typography style={{fontSize: "15px", color: Grey17, fontWeight: "400"}}>{userGroup.Name}</Typography>
                                        </div>
                                    </MenuItem>
                                ))}
                            </>
                        ) : null}
                        {company.Workgroups.length > 0 ? (
                            <>
                                <MenuItem key="workgroup-header" disabled className={css_self.Standard}>
                                    <div style={{display: "block", marginLeft: "10px"}}>
                                        <div>
                                            <Typography style={{fontSize: "15px", color: Grey17, fontWeight: "400"}}>Workgroups</Typography>
                                        </div>
                                    </div>
                                </MenuItem>
                                {company.Workgroups.map(workgroup => (
                                    <MenuItem key={workgroup.USID} className={css_self.Standard} onClick={() => {
                                        setMenu(null);
                                        appContext?.sessionContext.setActiveVisibility("Workgroup", workgroup.USID);
                                        EventBus.visibility.emit("change-visibility", "Workgroup");
                                    }}>&emsp;&emsp;
                                        <WorkOutline style={{color: Grey17}} />
                                        <div style={{display: "block", marginLeft: "10px"}}>
                                            <Typography style={{fontSize: "15px", color: Grey17, fontWeight: "400"}}>{workgroup.Name}</Typography>
                                        </div>
                                    </MenuItem>
                                ))}
                            </>
                        ) : null}
                    </Menu>
                ) : null}
            </>
        );
    }

    function renderAppsNavigation() {
        return (
            <div>
                <IconButton className={css_self.NavMenuButton} onClick={(e) => setMenu({id: "apps", target: e.currentTarget})}>
                    <Apps/>
                </IconButton>
                {menu?.id === "apps" ? (
                    <Popover anchorEl={menu.target}
                             anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                             transformOrigin={{vertical: -17, horizontal: "left"}}
                             open={menu !== null}
                             onClose={() => setMenu(null)}
                             TransitionProps={{
                                 onExited: () => setMenu(null),
                             }}
                    >
                        <Box sx={{p: 2, bgcolor: "background.paper", width: "375px", height: "1130px"}}>
                            <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
                                <div style={{display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderDocumentScreen) navigate("/document");
                                            }}>
                                            <Folder sx={{color: renderDocumentScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderDocumentScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Documents</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                 if (renderApprovalRequestScreen) navigate("/approval-request");
                                            }}>
                                            <Folder sx={{color: renderDocumentScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderDocumentScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Approval Requests</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                 if (renderRecordScreen) navigate("/record");
                                            }}>
                                            <Folder sx={{color: renderDocumentScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderDocumentScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Records</Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", borderTop: "1px solid #EEEEEE", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                 if (renderEquipmentScreen) navigate("/equipment");
                                            }}>
                                            <Folder sx={{color: renderDocumentScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderDocumentScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Equipment</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                 if (renderEquipmentSpecificationScreen) navigate("/equipment_specification");
                                            }}>
                                            <Folder sx={{color: renderDocumentScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderDocumentScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Equipment Specification</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", borderTop: "1px solid #EEEEEE", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                 if (renderSupplierScreen) navigate("/supplier");
                                            }}>
                                            <Folder sx={{color: renderSupplierScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderSupplierScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Supplier</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", borderTop: "1px solid #EEEEEE", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderPurchaseOrderScreen) navigate("/purchase-order");
                                            }}>
                                            <Folder sx={{color: renderPurchaseOrderScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderPurchaseOrderScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Purchase Order</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", borderTop: "1px solid #EEEEEE", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderReceivingScreen) navigate("/receiving");
                                            }}>
                                            <Folder sx={{color: renderReceivingScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderPurchaseOrderScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Receiving</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", borderTop: "1px solid #EEEEEE", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderPartScreen) navigate("/parts");
                                            }}>
                                            <Folder sx={{color: renderPartScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderPartScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Parts</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderEngineeringBuildScreen) navigate("/engineering-builds");
                                            }}>
                                            <Folder sx={{color: renderEngineeringBuildScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderEngineeringBuildScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Engineering Builds</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderLHRScreen) navigate("/lhr");
                                            }}>
                                            <Folder sx={{color: renderLHRScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderLHRScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>LHR</Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderLotScreen) navigate("/lot");
                                            }}>
                                            <Folder sx={{color: renderLotScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderLotScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Lot/Inventory</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderWorkOrderScreen) navigate("/work-order");
                                            }}>
                                            <Folder sx={{color: renderWorkOrderScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderWorkOrderScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Work Order</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", borderTop: "1px solid #EEEEEE", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderTrainingScreen) navigate("/training");
                                            }}>
                                            <Folder sx={{color: renderTrainingScreen ? "#1498D8" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderTrainingScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Training</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderTrainingCurriculumScreen) navigate("/training-curriculum");
                                            }}>
                                            <Folder sx={{color: renderTrainingCurriculumScreen ? "#000000" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderTrainingCurriculumScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Training Curriculum</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", borderTop: "1px solid #EEEEEE", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                if (renderAdministrationScreen) navigate("/administration");
                                            }}>
                                            <Folder sx={{color: renderAdministrationScreen ? "#000000" : "#BAC3CE", width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: renderAdministrationScreen ? "#000000" : "#BAC3CE", textAlign: "center"}}>Administration</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}/>
                                </div>
                            </div>
                        </Box>
                    </Popover>
                ) : null}
            </div>
        );
    }

    function renderMenuNavigation() {
        return (
            <div>
                <IconButton className={css_self.NavMenuButton} onClick={(e) => setMenu(menu === null ? {id: "menu", target: e.currentTarget} : null)}>
                    <MenuIcon/>
                </IconButton>
            </div>
        );
    }

}
