import axios from "axios";
import React, {createContext, useContext, useReducer} from "react";
import {useLocation} from "react-router-dom";
import {useCssPropertyWindowHeight} from "../../util/useCssProperty";
import LoginHelp from "./LoginHelp";
import LoginMain from "./LoginMain";
import LoginSecure from "./LoginSecure";
import useAppContext from "../../util/AppContext";

export const LoginContext = createContext(null);

export default function Login() {

    const [preSessionState, updatePreSessionState] = useReducer((state, action) => action(state), {});

    const {sessionContext} = useAppContext();

    const location = useLocation();

    const showHelp = location.pathname === "/help" || location.pathname === "/forgotPassword";

    async function login() {

        let preauthToken = localStorage.getItem("t1.preauth.v2");

        if (!preauthToken) {

            const response = await axios({
                method: "GET",
                baseURL: process.env.REACT_APP_API_URL,
                url: "t1/v1/authenticate",
                json: true,
                params: {
                    Type: "preauth",
                },
            }).catch(e => e.response || e);

            if (response instanceof Error) {
                return response;
            }

            if (response.status !== 200) {
                return response;
            }

            preauthToken = response.data.Token;
            localStorage.setItem("t1.preauth.v2", preauthToken);
        }

        const response = await axios({
            method: "POST",
            baseURL: process.env.REACT_APP_API_URL,
            url: "t1/v1/authenticate",
            json: true,
            headers: {
                "X-Enlil-AccessToken": preauthToken,
            },
            data: {
                Visibility: preSessionState.visibility,
                Email: preSessionState.email,
                Password: preSessionState.password,
                ...(preSessionState.secureLogin?.secureCode ? {
                    SecureCode: preSessionState.secureLogin.secureCode,
                    RememberDevice: preSessionState.secureLogin.rememberDevice,
                } : {}),
            },
        }).catch(e => e.response || e);

        if (response instanceof Error || response.status !== 200) {
            return response;
        }

        // multiple account
        if (response.data.Code === "CC-ADC7-49A2" || response.data.Code === "CC-E5EB-43D5") {
            return response;
        }

        // change initial password
        if (response.data.Code === "CC-240C-4072") {
            updatePreSessionState(state => {
                return {
                    ...state,
                    showSecureLogin: false,
                    accessToken: response.data["AccessToken"],
                };
            });

            return response;
        }

        // verify email/phone
        if (response.data.Code === "CC-3D1A-4802") {
            updatePreSessionState(state => {
                return {
                    ...state,
                    showSecureLogin: false,
                    accessToken: response.data.AccessToken
                };
            });
            return response;
        }

        // secure login
        if (response.data.Code === "CC-D802-4D8E") {
            updatePreSessionState(state => {
                return {
                    ...state,
                    showSecureLogin: true,
                    accessToken: response.data.AccessToken,
                    secureLogin: response.data.Identities,
                };
            });
            return response;
        }

        let activeVisibility = null;
        if (preSessionState.visibility) {
            let [company, visibilityMode, visibilityValue] = preSessionState.visibility.split(":");
            activeVisibility = [visibilityMode, visibilityValue];
        } else if (response.data["Company"]["Positions"].length > 0) {
            activeVisibility = ["Position", response.data["Company"]["Positions"][0].USID];
        } else if (response.data["Company"]["UserGroups"].length > 0) {
            activeVisibility = ["UserGroup", response.data["Company"]["UserGroups"][0].USID];
        } else if (response.data["Company"]["Workgroups"].length > 0) {
            activeVisibility = ["Workgroup", response.data["Company"]["Workgroups"][0].USID];
        }

        sessionContext.setIdentityToken(response.data["IdentityToken"])
        sessionContext.setSessionUSID(response.data["Session"])
        sessionContext.setAccessToken(response.data["AccessToken"])
        sessionContext.setRefreshToken(response.data["RefreshToken"])
        sessionContext.setActiveVisibility(activeVisibility)
        sessionContext.setCompanyName(response.data["Company"]["CompanyName"])
        sessionContext.setCompany(response.data["Company"])
        sessionContext.setScreens(response.data["Screens"])
        sessionContext.setRoles(response.data["Roles"])
        sessionContext.setShowLoginDialog(false)

        return response;
    }

    function abortLogin() {
        updatePreSessionState(() => ({}));
    }

    useCssPropertyWindowHeight([]);

    const LoginContextExports = {
        preSessionState,
        updatePreSessionState,
        abortLogin,
        login,
    };

    return (
        <LoginContext.Provider value={LoginContextExports}>
            {showHelp ? (
                <LoginHelp />
            ) : preSessionState.showSecureLogin ? (
                <LoginSecure />
            ) : (
                <LoginMain />
            )}
        </LoginContext.Provider>
    );
}

export function useLoginContext() {
    return useContext(LoginContext);
}
